import React from "react";
import Card1 from "../Cards/Card1/Card1";
import styles from "./styles.module.css";
import guarantee from "../../Assets/guarantee.svg";
import custom from "../../Assets/custom.svg";
import precision from "../../Assets/precision.svg";

const ProsSection = () => {
  return (
    <div className={styles.prosSec}>
      <h1>
        Delivering handcrafted quality products at your doorstep.
      </h1>
      <div
        className={`${styles.cardDiv} d-flex align-items-center justify-content-between`}
        style={{ gap: "1.25rem" }}
      >
        <Card1
          img={precision}
          heading="Handcrafted with precision"
          about="Experience artisanal excellence with our meticulously crafted diaries, bookmarks, and Polaroids. Each piece is lovingly handcrafted to perfection, ensuring unparalleled quality and attention to detail."
        />
        <Card1
          img={guarantee}
          heading="Made with premium materials"
          about="Indulge in our products crafted with premium paper and sustainable cardboard. Each item is thoughtfully designed to provide exceptional quality while minimizing environmental impact"
        />
        <Card1
          img={custom}
          heading="Customisation options available"
          about="Personalize your experience with our customisation options. Tailor your diaries, bookmarks, and Polaroids to reflect your unique style and personality, creating one-of-a-kind pieces that resonate with you."
        />
      </div>
    </div>
  );
};

export default ProsSection;
