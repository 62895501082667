import React from "react";
import styles from "./styles.module.css";
import diary from "../../Assets/Diary.svg";
import bookmark from "../../Assets/bookmark.svg";
import polaroid from "../../Assets/polaroid.svg";
import { useEffect } from "react";
import { filtercatagory } from "../../server/services/product/product.service";
import Products from '../../Components/Products/Products'
import SearchPage from "../../Pages/SearchPage/SearchPage";
import { useNavigate } from "react-router-dom";
const Display = () => {
  const navigate=useNavigate()

  const filter=(id)=>{
    filtercatagory(id)
    .then((res)=>{
      console.log(res)
      navigate("/search?query="+id)
      
      
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    const slider = document.querySelector('#products-slide');
let isDown = false;
let startX;
let scrollLeft;

slider.addEventListener('mousedown', (e) => {
  isDown = true;
  slider.classList.add('active');
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
});
slider.addEventListener('mouseleave', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mouseup', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mousemove', (e) => {
  if(!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider.offsetLeft;
  const walk = (x - startX) * 3; //scroll-fast
  slider.scrollLeft = scrollLeft - walk;
  console.log(walk);
});
  },[])
  return (
    <>
    <br/>
      <h1 className="heading">
        Our Products<span className="empText"> </span>
      </h1>
      
      <div className={styles.products}>
      <div className="d-flex align-items-center justify-content-between" id="product-slide">
        <div className={styles.card}>
          <img onClick={()=>{
            filter("Diary")
          }} src={diary} />
          <h3>Diary</h3>
          <h5>
            Welcome to our world of beautifully crafted diaries You can find
            them in diverse range of covers. They are hard bound and come with
            premium quality paper which will last with you forever.{" "}
          </h5>
        </div>
        <div className={styles.card}>
          <img onClick={()=>{
            filter("Polaroid")
          }} src={polaroid} />
          <h3>Polaroid</h3>
          <h5>
            Enough of this digital drama get yourself these polaroids to store
            your fond memories forever. These are not just a piece of paper
            rather phase of you real life and not reel life.
          </h5>
        </div>
        <div className={styles.card}>
          <img onClick={()=>{
            filter("Bookmark")
          }} src={bookmark} />
          <h3>Bookmark</h3>
          <h5>
            These bookmarks will excite you every time to find many more
            milestones. Comes in varied shapes and colours which will be a
            taking point in your next book club gathering.{" "}
          </h5>
        </div>
      </div>

      </div>
    </>
  );
};

export default Display;
