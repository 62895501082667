import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import success from "../../Assets/ordersuccess.svg"
import styles from "./styles.module.css"
import { useDispatch } from "react-redux";
import { loadCart } from '../../server/redux/actions/cart'

const OrderSuccess = () => {
  localStorage.removeItem("address")
  localStorage.removeItem("alias")
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch=useDispatch()
  useEffect(()=>{
    if(!location.state) navigate('/');
    else {
      if(!location.state.redirect) navigate('/');
    }
    console.log(location);
    setTimeout(() => {
      dispatch(loadCart())      
    }, 1000);
  },[])
  return (
    <div className={styles.success}>
    <img src={success} alt="Order Success"/>
    <h2>Payment recieved!</h2>
    <p className='empText'>Your order is placed successfully.</p>
    </div>
  )
}

export default OrderSuccess