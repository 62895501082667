import React from 'react'
import styles from "../styles.module.css"
import StarIcon from '@mui/icons-material/Star';

const RatingList = (props) => {
  // console.log(props)
  return (
    <div className={styles.ratings}>
        <h3 className='empText'> <StarIcon/>{props.review.rate}</h3>
        <p className='empText'>{props.review.name} | {props.review.date.split("T")[0]}</p>
        <h2>{props.review.userreview}</h2>
    </div>
  )
}

export default RatingList