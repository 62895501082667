import React from 'react'
import { Link } from 'react-router-dom'
import styles from "./styles.module.css"
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { signout } from '../../server/redux/actions/user';
import { useDispatch } from 'react-redux';


const SideMenu = () => {
  const dispatch=useDispatch()
  return (
    <div className={styles.sideMenu}>
    {/* <h1> <Link to="/"><ArrowBackIcon fontSize='large'/></Link>
    My Orders
    </h1>
    <br/> */}
    <div>
        <ul>
            <li><Link to="/wishlist"><FavoriteBorderOutlinedIcon/> Wishlist</Link></li>
            <li><Link to="/order-history"><Inventory2OutlinedIcon/> My Orders</Link></li>
            <li><Link to="/" onClick={()=>{dispatch(signout(true))}}><LogoutOutlinedIcon/>Logout</Link></li>
        </ul>
    </div>
    </div>
  )
}

export default SideMenu