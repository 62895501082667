import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.svg";
import {
  createOrder,
  getCart,
  verifyOrder,
} from "../../server/services/user/user.service";
import styles from "./styles.module.css";
import {
  ShimmerTitle,
  ShimmerButton,
  ShimmerBadge,
} from "react-shimmer-effects";
import { useDispatch } from "react-redux";
import { setLoading } from "../../server/redux/actions/loading";
import { message } from "antd";



const CartRate = (props) => {
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [delivery,setDelivery]=useState(40)
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const details = JSON.parse(localStorage.getItem("user"));

 

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };
  

  // const [shimmer,setShimmer] = useState(false)
  let shimmer = props.shimmer;
  let setShimmer = props.setShimmer;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getRate = async () => {
    setShimmer(true);
    let y = 0;
    let z = 0;
    await data.forEach((x) => {
      console.log(x.quantity)

      y += x.quantity * x.product.price;
      z += x.quantity;
    })
    if(y>499 && delivery===40)
    {
      setDelivery(0)
    }
    else if(y<=499 && delivery===0)
    {
      setDelivery(40)
    };
    setPrice(y);
    setDiscount(0);
    setShimmer(false);
  };

 

  // <button id="rzp-button1">Pay</button>

  const checkout = async function (e) {
    e.preventDefault();
    console.log("here i am");
    if(localStorage.getItem("address")===null)
    {
      navigate("/address")
    }
  else
   { 
    const seladdress=localStorage.getItem("address")
    dispatch(setLoading(true));
    let options;
    // const data = await fetch("/api/razorpay", { method: "POST" }).then((t) =>
    //   t.json()
    // );
    console.log(data);
    await createOrder((total+delivery) * 100,seladdress)
      .then((res) => {
        dispatch(setLoading(false));
        console.log(res.data.razorpay.id);
        options = {
          // CHANGE HERE TO GET FROM .env
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
          amount: (total+delivery) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Customate",
          description: "Transaction",
          image: logo,
          order_id: res.data.razorpay.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          // callback_url:BaseUrl+"payment/verify",
          // callback_url:"http://localhost:3000/",
          // redirect: true,
          prefill: {
            name: details.Name,
            email: details.email,
            contact: details.phoneNumber,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#008090",
          },
          handler: function (response) {
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature);
            console.log(response);
            dispatch(setLoading(true));
            response["id"] = res.data._id;
            verifyOrder(response)
              .then((res) => {
                dispatch(setLoading(false));
               
                console.log(res);
                

                navigate("/order-success", { state: { redirect: true } });
              })
              .catch((err) => {
                message("Payment Failed Buddy");
                setTimeout(() => {
                  navigate("/")                 
                  
                }, 2500);
                console.log(err);
              });
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((err) => {
        console.log(err);
      });}
  };

  useEffect(() => {
    getRate();
  }, [data]);



  useEffect(() => {
    setTotal(price - discount);
  }, [discount, price]);
  useEffect(() => {
    setShimmer(true);
    getCart()
      .then((res) => {
        setShimmer(false);
        setData(res.data.cart);
      })
      .catch((err) => {
        setShimmer(false);
        console.log(err);
      });
  }, [props.change]);
  useEffect(()=>{

  },[delivery],[])

 
  

  return (
    <div className={styles.cartRate}>
      {shimmer ? (
        <Container className={styles.container}>
          <Row className={styles.row}>
            {/* <ShimmerTitle line={1} gap={0} /> */}
            <Col>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
            <Col className={styles.second}>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
            <Col className={styles.second}>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
            <Col className={styles.second}>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
            <Col className={styles.second}>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
          </Row>
          <hr className={styles.dashed}></hr>
          <Row className={styles.row}>
            <Col>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
            <Col className={styles.second}>
              <ShimmerTitle line={1} gap={0} variant={"secondary"} />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className={styles.container}>
          <Row className={styles.row}>
            <Col>Total Price</Col>
            <Col className={styles.second}>₹ {price}</Col>
          </Row>
          <Row className={styles.row}>
            <Col>Discount</Col>
            <Col className={styles.second}>₹ {discount}</Col>
          </Row>
          
          <Row className={styles.row}>
            <Col>Delivery Charges</Col>
            
            <Col className={styles.second} style={{ color: "var(--primary)" }}>
              {price>499 ?(<>₹ 0</>):(<>₹ 40</>)}
              
            </Col>
          </Row>
          <Row className={styles.row}><sub style={{fontSize:"small"}}>Free Delivery for orders above ₹499</sub></Row>
          <hr className={styles.dashed}></hr>
          <Row className={styles.row}>
            <Col>Total Payable</Col>
            <Col className={styles.second}>₹ {total+delivery}</Col>
          </Row>
          <Row className={styles.row}>
            <Col>Address Alias</Col>
            <Col className={styles.second}>{localStorage.getItem("address")===null?(<>Not Selected</>):(<>{localStorage.getItem("alias")}</>)}</Col>
          </Row>
          <br/>
        </Container>
      )}
      {shimmer ? (
        <ShimmerButton size="lg" width="100%" className={styles.btnShimmer} />
      ) : (
        <div className={styles.alignstuff}>
        <button className="prim-btn" id={styles.rzpbutton1} onClick={()=>{navigate("/address")}}>
          Address
        </button>
        {"  "}
        <button className="prim-btn" id={styles.rzpbutton1} onClick={checkout}>
          Proceed
        </button>
        </div>
       
        
      )}
      {/* <ShimmerBadge width={"100%"} /> */}
    </div>
  );
};

export default CartRate;
