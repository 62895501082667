import React, { useState } from "react";
import "./style.css";
import footerlogo from "../../Assets/footer logo.svg";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  
  return (
    <footer className="footer">
      <div className="left">
        <ul className="list">
          <li className="li"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </li>
          <li className="li"
            onClick={() => {
              navigate("/explore");
            }}
          >
            Shop
          </li>
          <li className="li"
            onClick={() => {
              navigate("/about");
            }}
          >
            FAQ
          </li>
          <li className="li"
            onClick={() => {
              navigate("/about");
            }}
          >
            About Us
          </li>
          <li className="li"
            onClick={() => {
              navigate("/about");
            }}
          >
            Contact Us
          </li>
          <li className="li"
            onClick={() => {
              navigate("/about");
            }}
          >
            Delivery & Shipping
          </li>
          <br />
        
          
          
        </ul>
        <p className="customizet">Designed and Developed by DevTeam</p>

       
      </div>
      <div className="middle">
        <img
          className="footer-logo"
          src={footerlogo}
          height="40%"
          width="40%"
        />
        <br/>
        <p className="customized">Customised products shop</p>
        <br/>
        <p className="customized">
          Find us on{" "}
          <IconButton href="https://www.instagram.com/customate.in" target="_blank">
            <InstagramIcon style={{ color: "white" }} />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/customatediary/" target="_blank">
            <LinkedInIcon style={{ color: "white" }} />
          </IconButton>
        </p>
      </div>
      <div className="right">
        <p className="mailer" style={{fontSize:"21.6px"}}>
          For corporate or bulk orders write us at 
          <span><a className="a" href="mailto:contact@customate.in"> contact@customate.in</a></span>
        </p>
        <br />
        <br />
        <br />
        <br />
        <p className="under">Payments powered by Razorpay</p>
      </div>
    </footer>
  );
};

export default Footer;
