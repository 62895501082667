import React, { useEffect, useState } from "react";
import Products from "../../Components/Products/Products";
import ProsSection from "../../Components/ProsSection/ProsSection";
import TopBlock from "../../Components/TopBlock/TopBlock";
import { getAllProducts } from "../../server/services/product/product.service";
import Display from "../../Components/Display/Display";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const data = queryParams.get("data");

    if (data) {
      try {
        const parsedData = JSON.parse(decodeURIComponent(data));
        console.log(parsedData);
        console.log(parsedData.token);
        localStorage.setItem("user", JSON.stringify(parsedData));
        localStorage.setItem("token", parsedData.token);
        navigate("/");
      } catch (error) {
        console.log("Error parsing data:", error);
      }
    }
  }, []);

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        console.log(res.data);
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <TopBlock />
      <Display />
      <Products products={products} />
      <ProsSection />
    </>
  );
};

export default Home;
