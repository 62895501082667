import React from "react";
import { useState, useEffect } from "react";
import { getWishlist } from "../../server/services/user/user.service";
import { setLoading } from "../../server/redux/actions/loading";
import { useDispatch } from "react-redux";
import Card2 from "../../Components/Cards/Card2/Card2";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import empty from "../../Assets/empty.svg"

const Wishlist = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate=useNavigate()

  useEffect(() => {
    dispatch(setLoading(true));
    getWishlist()
      .then((res) => {
        console.log(res.data.wishlist);

        dispatch(setLoading(false));
        setData(res.data.wishlist);
        console.log(data);
        // console.log(data)
      })
      .catch((err) => {
        dispatch(setLoading(false));
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    const slider = document.querySelector("#products-slide");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }, []);

  return (
    <div className={styles.products}>
      {data.length === 0 && (
        <div className={styles.empty}><h1>Wish For Something!</h1>
        <button className='prim-btn' onClick={()=>{navigate("/explore")}}>Explore items</button>
        
        </div>
      )}
      <div
        className="d-flex align-items-center"
        id="products-slide"
      >
        {data.map((product, i) => {
          return <Card2 product={product} />;
        })}
      </div>
    </div>
  );
};

export default Wishlist;
