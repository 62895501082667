// import StarBorderIcon from '@mui/icons-material/StarBorder'
import React, { useEffect, useState } from 'react'
import List from './List'
import styles from "./styles.module.css"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const OrderView = (props) => {
    
    useEffect(()=>{
        console.log(props,props.data===undefined);
        if(props.data!==undefined){
            // let y=0
            // props.data.Item.forEach(x=>{
            //     y+=x.quantity
            // })
            // setDiscount(y*15)
        }
    },[props])

    
    
    const printBill = ()=>{
        // generate
        console.log("printing");
        window.print();
    }
  return (
    <div className={styles.orderView} id="section-to-print">
        {props.data?<><h2>
            Order Id {'#'}{props.data.razorpay.id.split("_")[1]}
            <span className={styles.capsule} style={props.data.status.toLowerCase()==="delivered"?{}:{backgroundColor:"rgba(240, 195, 98,0.2)",color:"rgba(240, 195, 98)"}}>{props.data.status[0].toUpperCase()+props.data.status.slice(1)}</span>
            {/* <span className={styles.price}>₹{(props.data.amount)/100}</span> */}
        </h2>
        <h3>{props.data.Item.length} items</h3>
        <div>
            {props.data.Item.map((ele)=><List data = {ele}/>)}
            {/* <List/> */}
        </div>
        {/* <p className={styles.rating}>Rate your experience <StarBorderIcon/> <StarBorderIcon/> <StarBorderIcon/> <StarBorderIcon/> <StarBorderIcon/></p> */}
        <h3>Bill details</h3>
        <div className={styles.bill}>
            <h4 className='empText'>Delivery<span>{(props.data.amount)/100>499?(<>₹ 0</>):(<>₹ 40</>)}</span></h4>
            <h4 className='empText'>Bill <span>₹ {(props.data.amount)/100}</span></h4>
        </div>
        <div className={styles.buttonDiv} id="dont-print">
        <button id="" className="prim-btn" onClick={()=>printBill()} style={{border: "1px solid #E6E7E7"}}><FileDownloadOutlinedIcon/>Download invoice</button>
        </div></>:<div style={{height:"200px"}}></div>}

    </div>
  )
}

export default OrderView