import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { otp, resendOtp } from "../../../server/services/auth/auth.service";
import { setLoading } from "../../../server/redux/actions/loading";
import { setUser } from "../../../server/redux/actions/user";
import { message } from "antd";
import LockIcon from "@mui/icons-material/Lock";
import { useLocation, useNavigate } from "react-router-dom";
import KeyIcon from "@mui/icons-material/Key";
import Turnstile from "react-turnstile";

import "./Otp.css";
// import ColumnGroup from "antd/es/table/ColumnGroup";
// import { CleaningServices } from "@mui/icons-material";

const Otp = () => {
  let location = useLocation();
  const [cloudFlare, setCloudFlare] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [resen, setResen] = useState(true);

  const [timer, setTimer] = useState(process.env.REACT_APP_TIMER);
  const [isTimerRunning, setIsTimerRunning] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(location);
    if (location.state === null) navigate("/signup");
  }, [location]);

  // useEffect(()=>{
  //     setTimeout(()=>{
  //         setResen(true)
  //     },60000)
  // },[])

  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(process.env.REACT_APP_TIMER); // Reset the timer to its initial value
  };

  useEffect(() => {
    let intervalId;

    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            setIsTimerRunning(false); // Stop the timer when it reaches 0
            clearInterval(intervalId);
          }
          return prevTimer - 1; // Decrease timer by 1 second
        });
      }, 1000); // Run every 1 second
    }

    return () => clearInterval(intervalId); // Clean up the interval when component unmounts
  }, [isTimerRunning]);
  const submit = (e) => {
    e.preventDefault();
    const myFormData = new FormData(e.target);
    var formDataObj = {};
    myFormData.forEach((value, key) => (formDataObj[key] = value));
    console.log(formDataObj);
    dispatch(setLoading(true));
    otp(location.state.email, formDataObj.otp)
      .then((res) => {
        console.log(res);
        if (res.code === 400) {
          message.error("Wrong OTP");
          setTimeout(() => {
            document.getElementById("otp").value = "";
            //  window.location.reload(false)
          }, 1000);
        } else if (location.state.type === "forgot" && res.code === 200) {
          navigate("/change-password", {
            state: { email: location.state.email },
          });
        } else if (res.code === 200) {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          dispatch(setUser(true));
          navigate("/");
        }

        dispatch(setLoading(false));
        // props.setUser(true)
        // navigate("/")
      })
      .catch((err) => {
        // if(err.response.code===404)
        // message.error("Incorrect OTP")
        // dispatch(setLoading(false))
        console.log(err);
      });
  };

  const resend = () => {
    startTimer();
    console.log(location.state.email);
    resendOtp({ email: location.state.email ,cloudflare:cloudFlare})
      .then((res) => {
        
        console.log(res);
        if(res.code===201)
        {
          console.log(res.data)
          message.error(res.data)
          setTimeout(() => {
            window.location.reload(false)
          }, 1500); //handled unique number error in 2XX code 
        }
        else{
          message.success("Otp Resent!");
        }
        // setResen(false)
        // setTimeout(()=>{setResen(true)},60000)
      })
      .catch((err) => {
        message.error("Otp cannot be resent!");
        console.log(err);
      });
  };
  useEffect(() => {
    if (window.outerWidth <= 768) {
      setIsMobile(true);
    } else setIsMobile(false);
  }, []);

  //function to keep check of mobile screen
  window.addEventListener("resize", () => {
    if (window.outerWidth <= 768) {
      setIsMobile(true);
    } else setIsMobile(false);
  });
  return (
    <div className={styles.loginBox}>
      <div className={styles.head}>
        <h1>Check your email</h1>
        <p className="empText">
          We sent a verification code to your email. Enter the code to proceed.
        </p>
      </div>
      <form
        className={`d-flex mr-auto ml-auto flex-wrap ${styles.formLogin}`}
        onSubmit={submit}
      >
        <div className="d-flex flex-direction-column mb-4">
          <label>Verification Code</label>
          <div className="d-flex">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <KeyIcon />
              </span>
            </div>

            <input
              id="otp"
              className="form-control me-2 border-0"
              name="otp"
              type="text"
              placeholder="*****"
              pattern="\d{5}"
              required
            />
            
            
          </div>
          <br/>
          
          <div className="d-flex flex-direction-column mb-4">
          
          <div className="d-flex">
          <input type="hidden" name="cloudflare"
         
          />
           <Turnstile  fixedSize="false" theme="light"
       
                sitekey={process.env.REACT_APP_SITE_KEY}
                onVerify={(token) => {
                console.log(token)
                  setCloudFlare(token)
      }}

            />
          </div>
           
        </div>
        
          {/* <p className='alerts'>{errors.password?.message}</p> */}
          <br />
          <div className={styles.forgo}>
            {!isTimerRunning ? (
              <button onClick={resend} className={styles.forgot}>
                Resend Otp
              </button>
            ) : (
              <button className={styles.forgot}>
                Resend OTP in {timer} seconds
              </button>
            )}
          </div>
        </div>

        <button className="prim-btn" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Otp;
