import React from 'react'
import styles from "./styles.module.css"
import { useNavigate } from 'react-router-dom';
import { useState ,useEffect } from 'react';
// import img1 from "../../Assets/banners/1.png";
// import img2 from "../../Assets/banners/2.png"
// import img3 from "../../Assets/banners/3.png"
// import img4 from "../../Assets/banners/4.png"



const images = [
  "https://res.cloudinary.com/sidata/image/upload/v1713290106/Customate banners/jpxaiiovgx1vyzlohlbx.png",
  "https://res.cloudinary.com/sidata/image/upload/v1713290113/Customate banners/bdunblfhtsrtlee4hfsa.png",
  "https://res.cloudinary.com/sidata/image/upload/v1713290103/Customate banners/khwqc5w4a5eplbhghpvc.png"

];

const Carousel = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const navigate=useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

 

  return (
    <div  className={styles.carousel}>
     
      <div onClick={()=>navigate("/explore")} className={styles.container}>
      {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="carousel"
            style={{
              display: index === currentImageIndex ? 'block' : 'none',
              visibility: imagesLoaded ? 'visible' : 'hidden',
            }}
            onLoad={handleImageLoad}
          />
        ))}
      </div>
     
    </div>
  );
};

export default Carousel;
