import React from "react";
import "./styles.css";
import image1 from "../../Assets/Horizontal img grp.svg";
import { useState,useEffect } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import founder from "../../Assets/profiles/Core/Founder.jpg";
import deepali from "../../Assets/profiles/Core/Deepali.jpg";
import prafful from "../../Assets/profiles/Core/Prafful.jpg";
import tara from "../../Assets/profiles/Core/Tara.jpg";
import arnav from "../../Assets/profiles/Dev/arnav.jpg";
import akshay from "../../Assets/profiles/Dev/akshay.jpg";
import mohit from "../../Assets/profiles/Dev/mohit.jpg";
import saksham from "../../Assets/profiles/Dev/saksham.jpg";
import priya from "../../Assets/profiles/Dev/priya.jpg"

const About = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  useEffect(() => {
    const slider = document.querySelector("#slide");
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }, []);

  const faqs = [
    {
      question: "What is the gsm of paper ?",
      answer: "Each diary contains plain white 100gsm pages of high quality.",
    },
    {
      question: "What are the no. of pages ?",
      answer: "Each diary contains 80 sheets of paper, totaling 160 pages",
    },
    {
      question: "What is the size and thickness of polaroids?",
      answer:
        "The size of Polaroids is (5.4 x 8.6)cm, with a thickness of 250 gsm.",
    },
    {
      question: "When will I receive my product?",
      answer:
        "Typically, it takes 1-3 days to complete your order. Delivery time depends on your location. We dispatch diaries from Delhi via India Post.",
    },
    {
      question: "What are the delivery charges ?",
      answer: "The delivery charge is ₹ 40 per order.",
    },
    {
      question: "How do I get diaries and Polaroids customised? ",
      answer:
        "For customization of diaries and Polaroids, please contact us on our Instagram handle @customate.",
    },
    {
      question: "What is the refund policy for an order? ",
      answer: "Currently, we do not offer any refund or return policy.",
    },
    {
      question: "What if I receive a damaged product?",
      answer:
        "Always record a video while opening your package. If the video shows that the product is damaged, we can proceed with a refund.",
    },
  ];

  const coreteamMembers = [
    {
      name: "Deepak Kumar",
      designation: "Founder",
      image: founder,
      linkedin: "https://www.linkedin.com/in/deepak-kumar-b17987217/",
    },
    {
      name: "Tara Goswami",
      designation: "PR Head",
      image: tara,
      linkedin: "https://www.linkedin.com/in/taragoswami",
    },    
    {
      name: "Prafful Ekka",
      designation: "Production Manager",
      image: prafful,
      linkedin: "https://www.linkedin.com/in/arnav2000agr/",
    },
    {
      name: "Deepali",
      designation: "Asst. Prod Manager",
      image: deepali,
      linkedin: "https://www.linkedin.com/in/arnav2000agr/",
    },
    
  ];
  const devteamMembers = [
    {
      name: "Akshay Bhatia",
      designation: "Backend Developer",
      image: akshay,
      linkedin: "https://www.linkedin.com/in/akshay-bhatia22",
    },
    {
      name: "Arnav Agarwal",
      designation: "Frontend Developer",
      image: arnav,
      linkedin: "https://www.linkedin.com/in/arnav2000agr/",
    },
    {
      name: "Mohit Singh",
      designation: "Backend Developer",
      image: mohit,
      linkedin: "https://linkedin.com/in/m0hitsingh",
    },
    {
      name:"Priya Jain",
      designation:"Backend Developer",
      image:  priya,
      linkedin:"https://www.linkedin.com/in/priya-jain1103/"
    },
    {
      name: "Saksham Tiwari",
      designation: "Frontend Developer",
      image: saksham,
      linkedin: "https://www.linkedin.com/in/saksham-tiwari-2346a2204/",
    }
    
  ];

  const renderTeamMembers = (team) => {
    return team.map((member, index) => (
      <div key={index} className="team-member" >
      
       <div className="team-member-image">
          <img src={member.image} alt={member.name} />
        </div>
        <div className="team-member-caption">
          <h4 className="team-member-name">
            {member.name}
            <a href={member.linkedin} target="blank">
              <LinkedInIcon fontSize="small" color="primary" />
            </a>
          </h4>
          <h4 className="team-member-designation">{member.designation}</h4>
        </div>
      
      </div>
    ));
  };

  const handleToggle = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <>
      <div className="about-img">
        <img src={image1} />
      </div>
      <br />
      <h1>Frequently Asked Questions (FAQ)</h1>
      <br />
      <div className="faq">
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <button
                className="faq-question"
                onClick={() => handleToggle(index)}
              >
                {faq.question}
              </button>
              {activeIndex === index && (
                <p className="faq-answer">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <br />
      <div className="about-img">
        <img src={image1} />
      </div>
      <br />
      <h1>About Us</h1>
      <div className="our-team">
        <div className="team-section">
          <h2>Core Team</h2>
          <div className="team-members"  id="slide">
            {renderTeamMembers(coreteamMembers)}
          </div>
        </div>
        <div className="team-section">
          <h2>Development Team</h2>
          <div className="team-members">
            {renderTeamMembers(devteamMembers)}
          </div>
        </div>
      </div>
      <br />
      <div className="about-img">
        <img src={image1} />
      </div>
      <br />
      <h1>Contact Us</h1>
      <p className="empText">
        We welcome your inquiries, feedback, and suggestions. Whether you have
        questions about our products/services, need assistance, or simply want
        to share your thoughts, we are here to help. You can email us directly
        at customate.in@gmail.com. We strive to respond to all inquiries
        promptly and provide you with the assistance you need. Additionally,
        stay connected with us on social media to receive updates, promotions,
        and announcements. Follow us on Instagram: @_customate_ to join our
        community and engage with us. Your input is valuable to us as we
        continuously strive to improve and enhance our offerings. We appreciate
        your time and look forward to connecting with you.
      </p>
      <h1>Delivery & Shipping</h1>
      <p className="empText">
        At Customate we prioritize prompt and efficient delivery of your orders.
        Once you've placed your order, our dedicated team works diligently to
        process it within 1 to 3 days. During this time, we carefully package
        each item to ensure its safe arrival at your doorstep. We dispatch all
        our products from our manufacturing facility in Delhi, India, leveraging
        the extensive network and reliability of India Post for deliveries.
        While delivery times may vary depending on your location, rest assured
        that we strive to minimize any delays and ensure your package reaches
        you in a timely manner. Upon dispatch, we provide you a unique
        consignment number, allowing you to track your package's journey through
        India Post's tracking system. Whether you're ordering locally or from
        afar, you can shop with confidence knowing that your order is in good
        hands with us.
      </p>
    </>
  );
};

export default About;
