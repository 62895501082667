import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import { useForm } from "react-hook-form";
import PersonIcon from "@mui/icons-material/Person";
import RoomIcon from "@mui/icons-material/Room";
import {
  postal,
  addNewAddress,
  getaddresses,
} from "../../server/services/user/user.service";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const Address = () => {
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [choice, setChoice] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const navigate=useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    getaddresses()
      .then((res) => {
        console.log(res.data);
        if (res.data.length === 0) setChoice(false);
        else setAddresses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [choice]);

  const handleSelection = (id,line1) => {
    setSelectedAddress(id);
    console.log(`Selected Address ID: ${id}`);
    localStorage.setItem("address",id)
    localStorage.setItem("alias",line1)
  };

  const pincodeValue = watch("pincode");

  useEffect(() => {
    if (pincodeValue && pincodeValue.length === 6) {
      handleChange(pincodeValue);
    }
  }, [pincodeValue]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    let formDataObj = {
      Name: data.Name.trim(),
      addressLine1: data.addressLine1.trim(),
      addressLine2: data.addressLine2.trim(),
      pincode: data.pincode,
      city,
      state,
      phoneNumber: data.phoneNumber,
    };
    console.log(formDataObj);
    addNewAddress(formDataObj)
      .then((res) => {
        console.log(res);
        setChoice(!choice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (value) => {
    postal(value)
      .then((res) => {
        console.log(res.data);
        setCity(res.data.city);
        setState(res.data.state);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const proceed=()=>{
    if(localStorage.getItem("address")!=null)
      navigate("/cart")
    else
    {
      message.error("Select an address first")

    }

  }

  return (
    <div className={styles.loginBox}>
      {choice ? (
        <>
          <div className={styles.head}>
            <h1>Saved Addresses</h1>
          </div>
          <div>
            <h2>Select an Address</h2>
            <div className={styles.addresslist}>
              {addresses.map((address) => (
                <div
                  key={address._id}
                  className={`${styles.addresscard} ${
                    selectedAddress === address._id ? styles.selected : ""
                  }`}
                  onClick={() => handleSelection(address._id,address.addressLine1)}
                >
                  <div className={styles.addressdetails}>
                    <h4>{address.Name}</h4>
                    <p>
                      {address.addressLine1},<br />
                      {address.addressLine2 && `${address.addressLine2},`}
                      <br />
                      {address.city}, {address.state} <br />
                      {address.pincode && `- ${address.pincode}`}
                      <br />
                      {address.phoneNumber && `Phone: ${address.phoneNumber}`}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.head}>
            <button
              onClick={() => {
                setChoice(!choice);
              }}
              className="prim-btn"
            >
              Add New
            </button>
            {"  "}
            <button
              onClick={()=>proceed()}
              className="prim-btn"
            >
              Proceed
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.head}>
            <h1>Add Address</h1>
          </div>
          <form
            className={`d-flex mr-auto ml-auto flex-wrap ${styles.formLogin}`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="d-flex flex-direction-column mb-4">
              <label>Name</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <PersonIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  name="Name"
                  type="text"
                  placeholder="Name"
                  {...register("Name", {
                    required: "name is required",
                    pattern: {
                      value: /^(([aA-zZ]+[\s]{0,1}){3,20})$/i,
                      message: "This is not a valid name",
                    },
                  })}
                />
              </div>
              <p className="alerts">{errors.Name?.message}</p>
            </div>
            <div className="d-flex flex-direction-column mb-4">
              <label>Address Line 1</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <RoomIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  name="addressLine1"
                  type="text"
                  placeholder="Address Line 1"
                  {...register("addressLine1", {
                    required: "Address is required",
                  })}
                />
              </div>
              <p className="alerts">{errors.addressLine1?.message}</p>
            </div>
            <div className="d-flex flex-direction-column mb-4">
              <label>Address Line 2</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <RoomIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  name="addressLine2"
                  type="text"
                  placeholder="Address Line 2"
                  {...register("addressLine2", {
                    required: "Address is required",
                  })}
                />
              </div>
              <p className="alerts">{errors.addressLine2?.message}</p>
            </div>
            <div className="d-flex flex-direction-column mb-4">
              <label>Pincode</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <RoomIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  name="pincode"
                  type="text"
                  placeholder="Pincode"
                  {...register("pincode", {
                    required: "pincode is required",
                    pattern: {
                      value: /^[1-9]\d{5}$/i,
                      message: "This is not a valid pincode",
                    },
                  })}
                />
              </div>
              <p className="alerts">{errors.pincode?.message}</p>
            </div>
            <div className="d-flex flex-direction-column mb-4">
              <label>City</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <RoomIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  name="city"
                  type="text"
                  value={city}
                  placeholder="City"
                />
              </div>
              <p className="alerts">{errors.state?.message}</p>
            </div>
            <div className="d-flex flex-direction-column mb-4">
              <label>State</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <RoomIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  name="state"
                  type="text"
                  value={state}
                  placeholder="State"
                />
              </div>
              <p className="alerts">{errors.state?.message}</p>
            </div>
            <div className="d-flex flex-direction-column mb-4">
              <label>Phone</label>
              <div className="d-flex">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <PhoneIcon />
                  </span>
                </div>
                <input
                  className="form-control me-2 border-0"
                  type="text"
                  placeholder="Enter phone no."
                  name="phoneNumber"
                  {...register("phoneNumber", {
                    required: "phone number is required",
                    pattern: {
                      value: /^[1-9]\d{9}$/i,
                      message: "This is not a valid phone number",
                    },
                  })}
                />
              </div>
              <p className="alerts">{errors.phoneNumber?.message}</p>
            </div>

            <button className="prim-btn" type="submit">
              Add Address
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default Address;
